import produce from 'immer'

import {
  FEATURE_SWITCH_DEFAULTS,
  SECONDARY_ORG_CATEGORY_LABELS,
  UPDATE_FEATURE_FLAG,
  UPDATE_FEATURE_FLAGS,
  UPDATE_FEATURE_SWITCH,
  UPDATE_GLOBAL_CONTEXT,
  UPDATE_IS_PAY_SETUP,
  UPDATE_ORDER_CUSTOMFIELD,
  UPDATE_ORDER_ITEM_CUSTOMFIELD,
  UPDATE_USER,
} from './constants'
import { type GlobalActions, type IGlobalState } from './types'
import { FEATURE_FLAG_DEFAULTS } from '../../services/FeatureFlag'

export const initialState: IGlobalState = {
  user: null,
  domain: '',
  env: 'development',
  featureSwitches: {
    ...FEATURE_SWITCH_DEFAULTS,
  },
  baseCurrency: {
    id: 1,
    name: 'CAD',
    rate: '1.000000',
  },
  navigationPermissions: [],
  permissions: [],
  endpoints: {
    media: '/',
    pix: '/',
    static: '/',
  },
  customfields: {
    order: [],
    orderItem: [],
  },
  systemPreferences: {
    locale: 'en-us',
    rfo_lock_default: false,
    'custom-labels': {
      location: {
        singular: 'Location',
        plural: 'Locations',
      },
      department: {
        singular: SECONDARY_ORG_CATEGORY_LABELS.SINGULAR,
        plural: SECONDARY_ORG_CATEGORY_LABELS.PLURAL,
      },
    },
    'bill-received-only': false,
    'default-disclaimer': null,
  },
  featureFlags: {
    ...FEATURE_FLAG_DEFAULTS,
  },
  hasFeatureFlagsResolved: false,
  sdkKeys: {
    LAUNCHDARKLY_CLIENT_ID: '',
    ZENDESK_API_KEY: '',
  },
  segmentAnalyticsMetadata: null,
  isPaySetUp: null,
  renderedBy: 'react',
  applicationStatus: {
    lock_mode: false,
    trial_mode: false,
  },
  role: {},
  headquartersTimezone: 'America/Vancouver',
  setupProgress: {
    accountcode_setup: false,
    approvalgroup_setup: false,
    approvalrouting_setup: false,
    basic_company_setup: false,
    budget_setup: false,
    completed_count: 0,
    completed_percentage: 0,
    database_initialized: 0,
    demo_data_cleared: false,
    department_setup: 0,
    show: false,
    user_setup: false,
    spending_card_bank_account_setup: false,
    is_financial_account_setup: false,
  },
}

export const reducer = (state = initialState, action: GlobalActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_GLOBAL_CONTEXT:
        return {
          ...draft,
          ...action.global,
        }
      case UPDATE_ORDER_CUSTOMFIELD:
        draft.customfields = {
          ...draft.customfields,
          order: action.customfields,
        }
        break
      case UPDATE_ORDER_ITEM_CUSTOMFIELD:
        draft.customfields = {
          ...draft.customfields,
          orderItem: action.customfields,
        }
        break
      case UPDATE_FEATURE_FLAG:
        draft.featureFlags[action.flag] = action.value
        break
      case UPDATE_FEATURE_SWITCH:
        draft.featureSwitches[action.flag] = action.value
        break
      case UPDATE_FEATURE_FLAGS:
        draft.featureFlags = {
          ...draft.featureFlags,
          ...action.flags,
        }
        draft.hasFeatureFlagsResolved = true
        break
      case UPDATE_USER:
        draft.user = {
          ...draft.user,
          ...action.user,
        }
        break
      case UPDATE_IS_PAY_SETUP:
        draft.isPaySetUp = action.value
        break
    }
  })
