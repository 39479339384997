import { Permissions } from '@webapp/models/enums'
import { useCheckPermission } from '../useCheckPermission'

export const usePaymentPermissions = () => {
  const canCreatePayments = useCheckPermission(
    Permissions.ACCOUNTS_PAYABLE_CREATE_PAYMENTS
  )
  const canManageLinkedBankAccounts = useCheckPermission(
    Permissions.SETTINGS_MANAGE_LINKED_BANK_ACCOUNTS
  )

  return {
    canCreatePayments,
    canManageLinkedBankAccounts,
  }
}
