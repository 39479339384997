import { type Enum } from '@webapp/utils'

export const PunchOutSupplierIds = {
  AMAZON_US: 1,
  AMAZON_DE: 2,
  AMAZON_GB: 3,
  AMAZON_CA: 4,
  STAPLES_US: 5,
  STAPLES_CA: 6,
  AMAZON_ES: 7,
} as const

export type PunchOutSupplierId = Enum<typeof PunchOutSupplierIds>

export const PunchOutDomains = {
  ['AMAZON.COM']: 'AMAZON.COM',
  ['AMAZON.DE']: 'AMAZON.DE',
  ['AMAZON.CO.UK']: 'AMAZON.CO.UK',
  ['AMAZON.CA']: 'AMAZON.CA',
  ['AMAZON.ES']: 'AMAZON.ES',
  ['STAPLES.COM']: 'STAPLES.COM',
  ['STAPLES.CA']: 'STAPLES.CA',
} as const

export type PunchOutDomain = Enum<typeof PunchOutDomains>

export const PunchOutSupplierSetupGuideUrls = {
  [PunchOutDomains['AMAZON.COM']]:
    'https://success.procurify.com/hc/en-us/articles/4406697816599',
  [PunchOutDomains['AMAZON.DE']]:
    'https://success.procurify.com/hc/en-us/articles/4406697816599',
  [PunchOutDomains['AMAZON.CO.UK']]:
    'https://success.procurify.com/hc/en-us/articles/4406697816599',
  [PunchOutDomains['AMAZON.CA']]:
    'https://success.procurify.com/hc/en-us/articles/4406697816599',
  [PunchOutDomains['AMAZON.ES']]:
    'https://success.procurify.com/hc/en-us/articles/4406697816599',
  [PunchOutDomains['STAPLES.COM']]:
    'https://success.procurify.com/hc/en-us/articles/4403180339479',
  [PunchOutDomains['STAPLES.CA']]:
    'https://success.procurify.com/hc/en-us/articles/4403180339479',
}

export type PunchOutSupplierSetupGuideUrl = Enum<
  typeof PunchOutSupplierSetupGuideUrls
>

export const PunchOutSupportedProtocolMethods = {
  PUNCHOUT_SETUP_REQUEST: 'POSR',
  PUNCHOUT_ORDER_MESSAGE: 'POOM',
  ORDER_REQUEST: 'OR',
  CONFIRMATION_REQUEST: 'CR',
}

export type PunchOutSupportedProtocolMethod = Enum<
  typeof PunchOutSupportedProtocolMethods
>
