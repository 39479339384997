import Decimal from 'decimal.js'

import { type BudgetGroupBudget } from '@webapp/models'
import { DEFAULT_FORMAT_OPTIONS } from './constants'
import type {
  IFixedBudget,
  FixedBudgetSubtotalList,
  IFixedBudgetFormat,
  IFlexibleBudget,
  IFlexibleBudgetFormat,
  BudgetSubtotalList,
  BudgetFormat,
} from './types'
import { useFormatBudgetValues } from './useFormatBudgetValues'

type BudgetFormatter = (budget: BudgetGroupBudget) => BudgetFormat

export const useFormatBudgetSubtotals = (
  options = DEFAULT_FORMAT_OPTIONS
): BudgetFormatter => {
  const mergeOptions = { ...DEFAULT_FORMAT_OPTIONS, ...options }
  const { formatBudgetPercent, formatBudgetPrice } =
    useFormatBudgetValues(mergeOptions)

  return (budget: BudgetGroupBudget): BudgetFormat => {
    if (!budget) return

    if (budget.amount == null) {
      const subtotalValues = calculateFlexibleBudgetValues(
        budget as IFlexibleBudget
      )
      // TODO [FT-6865]: remove formatted values from hook and just calc values
      const subtotals = formatFlexibleBudgetSubtotals(
        subtotalValues,
        formatBudgetPrice
      )

      return {
        budgetType: 'flexible',
        id: budget.id,
        isOverBudget: false,
        ...subtotals,
      }
    } else {
      const subtotalValues = calculateFixedBudgetValues(budget as IFixedBudget)
      const subtotals = formatFixedBudgetValues(
        subtotalValues,
        formatBudgetPercent,
        formatBudgetPrice
      )

      return {
        budgetType: 'fixed',
        id: budget.id,
        isOverBudget: subtotalValues.remaining.isNegative(),
        ...subtotals,
      }
    }
  }
}

const calculateSharedBudgetValues = (
  budget: BudgetGroupBudget
): FlexibleBudgetSubtotalsMap => {
  const approved = new Decimal(budget.approved ?? 0)
  const purchased = new Decimal(budget.purchased ?? 0)
  const billed = new Decimal(budget.invoiced ?? 0)
  const received = new Decimal(budget.received ?? 0)
  const committed = approved.plus(purchased).plus(billed).plus(received)
  const pending = new Decimal(budget.pending ?? 0)
  const spendRequest = new Decimal(budget.request_pending_amount ?? 0)
  const spendTotal = committed.plus(pending).plus(spendRequest)

  return {
    approved,
    billed,
    committed,
    pending,
    purchased,
    received,
    spendRequest: spendRequest.isZero() ? null : spendRequest,
    spendTotal,
  }
}

type FlexibleBudgetSubtotalsMap = Record<BudgetSubtotalList, Decimal>

const calculateFlexibleBudgetValues = (
  budget: IFlexibleBudget
): FlexibleBudgetSubtotalsMap => {
  return calculateSharedBudgetValues(budget)
}

type BudgetFormatValues = ReturnType<typeof useFormatBudgetValues>

const formatFlexibleBudgetSubtotals = (
  subtotals: FlexibleBudgetSubtotalsMap,
  formatBudgetPrice: BudgetFormatValues['formatBudgetPrice']
): IFlexibleBudgetFormat => {
  const subtotalsList = {} as IFlexibleBudgetFormat

  Object.keys(subtotals).forEach((key) => {
    const decimal: Decimal = subtotals[key]
    if (!decimal) return
    const amount = decimal.toFixed()
    subtotalsList[key] = {
      amount: formatBudgetPrice(amount),
    }
  })

  return subtotalsList
}

type FixedBudgetSubtotalsMap = Record<FixedBudgetSubtotalList, Decimal>

const calculateFixedBudgetValues = (
  budget: IFixedBudget
): FixedBudgetSubtotalsMap => {
  const {
    approved,
    billed,
    committed,
    pending,
    purchased,
    received,
    spendRequest,
    spendTotal,
  } = calculateSharedBudgetValues(budget)
  const total = new Decimal(budget.amount ?? 0)
  const remaining = new Decimal(budget.variance ?? 0)
  const remainingMinusPending = remaining.minus(pending)

  return {
    billed,
    approved,
    purchased,
    pending,
    remaining,
    received,
    committed,
    total,
    remainingMinusPending,
    spendRequest,
    spendTotal,
  }
}

const formatFixedBudgetValues = (
  subtotals: FixedBudgetSubtotalsMap,
  formatBudgetPercent: BudgetFormatValues['formatBudgetPercent'],
  formatBudgetPrice: BudgetFormatValues['formatBudgetPrice']
): IFixedBudgetFormat => {
  const subtotalsList = {} as IFixedBudgetFormat

  Object.keys(subtotals).forEach((key) => {
    const decimal: Decimal = subtotals[key]
    if (!decimal) return
    const amount = decimal.toFixed()
    const percentage = decimal.dividedBy(subtotals.total)
    const percentageRatio = percentage?.toFixed()
    const percentageNumber = percentage?.times(100).toDP(4).toNumber() ?? 0
    subtotalsList[key] = {
      amount: formatBudgetPrice(amount),
      percentage: {
        amount: formatBudgetPercent(percentageRatio),
        number: percentageNumber,
      },
    }
  })

  return subtotalsList
}
