import { useSelector } from 'react-redux'

import { type Permissions } from '@webapp/models/enums'
import { makeSelectPermissions } from '@webapp/stores/global'
import { hasPermission } from '@webapp/utils/permissions'

export const useCheckPermission = (permission: Permissions): boolean => {
  const permissions = useSelector(makeSelectPermissions()) || []

  return hasPermission(permissions, permission)
}

export const useFilterPermissions = (
  permissions: Permissions[]
): Permissions[] => {
  const allPermissionsArray = useSelector(makeSelectPermissions()) || []
  const allPermissionsSet = new Set(
    allPermissionsArray.map((perm) => perm.codename)
  )

  return permissions.filter((permission) => allPermissionsSet.has(permission))
}
